import React from "react";
import { graphql } from "gatsby";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import SEO from "../Components/SEO/index";
import LottieContent from "../Components/Lottie/index";

const paragraphStyles = {
  marginBottom: 48,
};

const LinkMenu = styled(Link)`
  color: #000;
  text-decoration: none;
  color: #868686;

  ::before {
    content: "← ";
  }
  :hover {
    text-decoration: underline;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
        `}
      />
      <SEO title="404 not found" />
      <div
        style={{
          height: "100vh",
          width: "100vh",
          margin: "0 auto",
          display: "grid",
          gridAutoFlow: "row",
          alignItems: "center",
        }}
      >
        <LottieContent
          style={{
            width: "100%",
            height: "100%",
          }}
          pathName="NotFoundPage.json"
        />

        <div style={paragraphStyles}>
          <br />
          <LinkMenu to="/">Go home</LinkMenu>.
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
