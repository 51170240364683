import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import lottie from "lottie-web";
import { Skeleton } from "@material-ui/lab";

const LottieContainer = styled.div`
  transition: opacity 0.7s ease-in;
  .fadeOut {
    opacity: 0;
  }
  .fadeIn {
    opacity: 1;
  }
`;

const LottieContent = ({ pathName, speed }) => {
  const lottieRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      if (!entries[0].isIntersecting) {
        return;
      } else {
        import(`./${pathName}`).then((jsonData) => {
          const animContent = lottie.loadAnimation({
            container: entries[0].target,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: jsonData,
            rendererSettings: {
              progressiveLoad: true,
            },
          });
          animContent.addEventListener("DOMLoaded", (e) => {
            setIsLoaded(true);
          });

          animContent.setSpeed(speed ? speed : 1);
          // delete ====
          // animContent.stop();
        });
        entries[0].target.classList.replace("fadeOut", "fadeIn");
        videoObserver.unobserve(entries[0].target);
      }
    });

    if (lottieRef.current) videoObserver.observe(lottieRef.current);
    return () => {
      videoObserver.disconnect();
    };
  }, [pathName, speed]);

  return (
    <div
      style={{
        position: "relative",
        paddingTop: "78%",
      }}
    >
      <LottieContainer
        className="fadeOut"
        style={{
          opacity: isLoaded ? 1 : 0,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
        ref={lottieRef}
      />
      {!isLoaded && (
        <Skeleton
          variant="rect"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  );
};

export default LottieContent;
